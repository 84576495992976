.bodyC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.cards-containerC {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  width: 90vw;
  padding-left: 7vw;
  padding-right: 7vw;
  flex-direction: row;
  justify-content: space-between;
}

.cardC {
  border-radius: 25px;
  padding: 60px;
  width: 35vw;
  display: flex;
  flex-direction: column;
}

.globeC {
  width: 35vw;
  border-radius: 25px;
}

.card-titleC {
  color: white;
  font-size: 50px;
  font-weight: bolder;
}

.card-bodyC {
  color: white;
  margin-top: 30px;
  font-size: 28px;
}

.countriesC {
  background-color: #f5efd8;
  border-radius: 25px;
  width: 76.5vw;
  min-height: 350px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 60px;
  padding-top: 55px;
}

.countriesC section {
  display: flex;
  flex-direction: row;
}

.countriesC img {
  height: 100px;
  margin: 10px;
}

.countriesC div {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  align-items: flex-start;
}

.comming-soonC {
  min-width: 250px;
  cursor: pointer;
  padding: 7px;
  padding-right: 45px;
  padding-left: 40px;
  margin: 5px;
  background-color: #313131;
  align-items: center;
  border-radius: 30px;
}

.comming-soonC:active {
  background-color: #2a65e3;
}

.comming-soonC span {
  color: white;
  font-size: 20px;
}

.comming-soonC img {
  height: 35px;
  width: 35px;
}

.currenciesC {
  background-color: #ddeee6;
  border-radius: 25px;
  width: 76.5vw;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 60px;
  padding-top: 55px;
}

.visaIEC {
  border-radius: 25px;
  width: 76.5vw;
  min-height: 330px;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  margin-top: 50px;
  justify-content: space-between;
}

.visaCardC {
  border-radius: 25px;
  width: 32vw;
  display: flex;
  flex-direction: column;
  flex: 0.48;
}

.visaCardC img {
  border-radius: 25px;
  height: auto;
  width: 100%;
}

.partnersC {
  width: 76.5vw;
  min-height: 330px;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  margin-top: 50px;
  justify-content: space-between;
}
.partnersLeftC {
  border-radius: 25px;
  padding: 60px;
  padding-top: 55px;
  background-color: #f5efd8;
  flex: 0.25;
}
.partnersRightC {
  flex: 0.65;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.partnersRightC div {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.32;
  align-items: center;
  justify-content: space-between;
}

.partnerLogoC {
  flex: 0.48;
  background-color: #f5efd8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  width: 100%;
}

.partnerIconC {
  width: 10vw;
  height: auto;
}

.partnerIcon2C {
  width: 15vw;
  height: auto;
}

.partnersMC {
  display: none;
}
/* phone and protrait tablet */
@media (max-width: 800px) {
  .cards-containerC {
    flex-direction: column;
    margin-bottom: 60px;
    margin-top: 0px;
    padding-left: 0vw;
    padding-right: 0vw;
  }

  .cards-containerC div {
    margin-bottom: 50px;
    flex-direction: column;
    min-height: 60vh;
  }

  .cardC {
    margin-bottom: 0px;
    width: 90vw;
  }

  .globeC {
    width: 90vw;
    border-radius: 25px;
  }

  .countriesC {
    flex-direction: column;
    width: 90vw;
  }
  .currenciesC {
    width: 90vw;
  }

  .visaIEC {
    width: 90vw;
    flex-direction: column;
    margin-bottom: 60px;
    margin-top: 0px;
    padding-left: 0vw;
    padding-right: 0vw;
    align-items: center;
  }

  .visaCardC {
    margin-bottom: 0px;
    width: 90vw;
  }

  .visaCardC img {
    margin-top: 40px;
  }
  .partnersC {
    width: 90vw;
    flex-direction: column;
  }
  .partnersRightC {
    display: none;
  }
  .partnersMC {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }
  .partnerLogoMC {
    width: 100%;
    height: 30vh;
    background-color: #f5efd8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    margin-top: 5vh;
  }
  .partnerIconC {
    width: 75%;
    height: auto;
  }

  .partnerIcon2C {
    width: 90%;
    height: auto;
  }
}
