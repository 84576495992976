.bodyBN {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contact-usBN {
  min-height: 200px;
  width: 78vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;
}

.contact-usBN span {
  color: black;
  text-decoration: none;
}

.centerBN {
  display: flex;
  flex-direction: column;
  padding: 50px;
  border-radius: 25px;
  background-color: #dedede;
}

.contact-titleC {
  color: black;
  font-size: 50px;
  font-weight: bolder;
}

.linksBN {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.linksBN section {
  display: flex;
  flex-direction: column;
}

.linksBN span {
  font-size: 28px;
}

.logoBN {
  height: 40px;
  margin-top: 100px;
  margin-bottom: 80px;
}

/* phone and protrait tablet */
@media (max-width: 800px) {
  .contact-usBN {
    align-items: center;
    flex-direction: column;
  }
  .centerBN {
    width: 90vw;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
  }
  .linksBN {
    align-items: center;
    flex-direction: column;
    flex: 1;
  }

  .linksBN section {
    width: 58vw;
    flex-direction: column;
    justify-content: space-between;
  }
}
