.bodyH {
  min-height: 80vh;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(
    to bottom left,
    #2a65e3,
    #295bd9,
    #2954d2,
    #283dbd
  );
}

.body-centerH {
  padding-bottom: 100px;
  width: 90vw;
  border-radius: 25px;
}

.body-containerH {
  display: flex;
  flex-direction: row;
}

.center-leftH {
  flex: 0.6%;
  display: flex;
  padding-left: 100px;
  height: auto;
  align-items: flex-start;
  padding-top: 100px;
}

.center-leftH section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.link_containerH {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 40px;
}

.qr_containerH {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 6vw;
  align-items: center;
  justify-content: center;
}

.qr_containerH div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.qr_containerH {
  color: #ffffff;
  font-size: 18px;
}
.qr_containerH span {
  text-align: center;
}

.qr_image {
  height: 130px;
  width: auto;
  border-radius: 10px;
}

.smile-logoH {
  height: 35px;
}

.title-textH {
  font-size: 50px;
  font-weight: bold;
  color: white;
  margin-top: 40px;
}

.body-textH {
  font-size: 28px;
  color: white;
  margin-bottom: 50px;
}

.button-containerH {
  cursor: pointer;
  background-color: white;
  height: 40px;
  width: 200px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
}

.button-containerH:active {
  background-color: rgb(208, 207, 207);
}

.button-containerH img {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.button-textH {
  color: #2a65e3;
  font-size: 17px;
}

.center-rightH {
  flex: 0.4%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-top: 100px;
  padding-right: 50px;
}

.center-rightH img {
  height: 95%;
  width: auto;
}

.abyssinayH {
  padding-left: 5px;
  margin-top: 50px;
  transform: translateY(70px);
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.abyssinayH img {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.abyssinayH span {
  margin-left: 13px;
  color: white;
}

/* protrait tablet */
@media (max-width: 800px) {
  .body-centerH {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0px;
    width: 100vw;
    padding-left: 30px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .center-leftH {
    padding-left: 50px;
  }
  .center-rightH {
    padding-right: 50px;
  }
  .smile-logoH {
    margin-left: 50px;
  }
}

/* phone  */
@media (max-width: 700px) {
  .body-centerH {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0px;
    width: 100vw;
    padding-left: 30px;
    padding-right: 20px;
  }

  .smile-logoH {
    transform: translateY(0px);
    transform: translatex(-10px);
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
  }

  .center-leftH {
    flex: 1;
    padding-left: 0px;
    padding-top: 0px;
  }

  .center-leftH section {
    align-items: flex-start;
  }

  .center-rightH {
    display: none;
  }
  .qr_containerH {
    display: none;
  }
  .link_containerH {
    width: 85vw;
    align-items: center;
    justify-content: center;
  }
}
