.privacy_policy_container{
  padding: 100px;
  padding-top: 50px;
}

/* phone and protrait tablet */
@media (max-width: 800px) {
  .privacy_policy_container{
    padding: 50px;
    padding-top: 25px;
  }
}